import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireStorage } from "@angular/fire/storage";
import {
  AssessmentForm,
  Counter,
  ReferralSlip,
  SpectacleForm,
  User,
} from "../model/aio.model";
import { BehaviorSubject, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AlertController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AioService {

  private allAssessmentsSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public allAssessments$: Observable<any> = this.allAssessmentsSubject.asObservable();


  tempAssessments: any;;
  tempUser: User[];
  tempFacilityName: AssessmentForm[];

  tempEmail: any;
  tempIsPhc: boolean = false;
  tempId: any;
  servEmail: any;
  selectedEmail: any;
  temporaryDistrict: any;

  usersCollection: AngularFirestoreCollection<User>;
  usersDoc: AngularFirestoreDocument<User>;
  usersItem: Observable<User[]>;

  assessmentFormsCollection: AngularFirestoreCollection<AssessmentForm>;
  assessmentFormsDoc: AngularFirestoreDocument<AssessmentForm>;
  assessmentFormsItem: Observable<AssessmentForm[]>;

  spectacleDispensingFormsCollection: AngularFirestoreCollection<SpectacleForm>;
  spectacleDispensingFormsDoc: AngularFirestoreDocument<SpectacleForm>;
  spectacleDispensingFormsItem: Observable<SpectacleForm[]>;

  referralSlipsCollection: AngularFirestoreCollection<ReferralSlip>;
  referralSlipsDoc: AngularFirestoreDocument<ReferralSlip>;
  referralSlipsItem: Observable<ReferralSlip[]>;

  counterCollection: AngularFirestoreCollection<Counter>;
  counterDoc: AngularFirestoreDocument<Counter>;
  counterItem: Observable<Counter[]>;

  constructor(
    private afs: AngularFirestore,
    private afa: AngularFireAuth,
    private afg: AngularFireStorage,
    private alertCtrl: AlertController
  ) {
    this.usersCollection = this.afs.collection("users");
    this.fetchDataFromFirestore();
    this.assessmentFormsCollection = this.afs.collection("assessmentForms");
    this.spectacleDispensingFormsCollection = this.afs.collection(
      "spectacleDispensingForms"
    );
    this.referralSlipsCollection = this.afs.collection("referralSlips");
    this.counterCollection = this.afs.collection("counter");
  }

  

  getScsByDistrict(district) {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("district", "==", district).where("phc", "==", "false");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.usersItem;
  }


  getUser(email) {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("email", "==", email);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.usersItem;
  }

  getAssessmentReportsByEmail(email) {
    this.assessmentFormsItem = this.afs
      .collection("assessmentForms", (ref) => {
        return ref.where("email", "==", email).orderBy("uid", "desc");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as AssessmentForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.assessmentFormsItem;
  }

  getPhcFacilities() {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("phc", "==", "true");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.usersItem;
  }

  getPhcAssessmentReportsByEmail(email) {
    this.assessmentFormsItem = this.afs
      .collection("assessmentForms", (ref) => {
        return ref.where("mappedTo", "==", email);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as AssessmentForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.assessmentFormsItem;
  }

  getAssessmentReportsById(id) {
    this.assessmentFormsItem = this.afs
      .collection("assessmentForms", (ref) => ref.where("id", "==", id))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as AssessmentForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.assessmentFormsItem;
  }

  getAssessmentReportsByUid(uid) {
    this.assessmentFormsItem = this.afs
      .collection("assessmentForms", (ref) => ref.where("uid", "==", uid))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as AssessmentForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.assessmentFormsItem;
  }

  getCounter() {
    this.counterItem = this.afs
      .collection("counter", (ref) => ref.orderBy("value", "asc"))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as Counter;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.counterItem;
  }

  getAssessmentReportsByDistrict(district) {
    this.assessmentFormsItem = this.afs
      .collection("assessmentForms", (ref) =>
        ref.where("district", "==", district)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as AssessmentForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.assessmentFormsItem;
  }

  getSpectacleReportsByDistrict(district) {
    this.spectacleDispensingFormsItem = this.afs
      .collection("spectacleDispensingForms", (ref) => {
        return ref.where("district", "==", district);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as SpectacleForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.spectacleDispensingFormsItem;
  }

  getSpectacleReportsByEmail(email) {
    this.spectacleDispensingFormsItem = this.afs
      .collection("spectacleDispensingForms", (ref) => {
        return ref.where("email", "==", email);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as SpectacleForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.spectacleDispensingFormsItem;
  }

  getReferralReportsByDistrict(district) {
    this.referralSlipsItem = this.afs
      .collection("referralSlips", (ref) => {
        return ref.where("district", "==", district);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as ReferralSlip;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.referralSlipsItem;
  }

  getReferralReportsByEmail(email) {
    this.referralSlipsItem = this.afs
      .collection("referralSlips", (ref) => {
        return ref.where("email", "==", email);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as ReferralSlip;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.referralSlipsItem;
  }

  getSpectacleReportsById(id) {
    this.spectacleDispensingFormsItem = this.afs
      .collection("spectacleDispensingForms", (ref) => {
        return ref.where("id", "==", id);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as SpectacleForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.spectacleDispensingFormsItem;
  }

  getReferralReportsById(id) {
    this.referralSlipsItem = this.afs
      .collection("referralSlips", (ref) => {
        return ref.where("id", "==", id);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as ReferralSlip;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.referralSlipsItem;
  }

  getAssessmentReport(id) {
    this.assessmentFormsItem = this.afs
      .collection("assessmentForms", (ref) => {
        return ref.where("id", "==", id);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as AssessmentForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.assessmentFormsItem;
  }

  private fetchDataFromFirestore() {
    // Fetch data from Firestore and update the BehaviorSubject
    this.afs.collection('assessmentForms').valueChanges().subscribe(data => {
      this.allAssessmentsSubject.next(data);
      
    });
  }

  getAllAssessmentReports() {
    this.assessmentFormsItem = this.afs
      .collection("assessmentForms", (ref) => {
        return ref;
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as AssessmentForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.assessmentFormsItem;
  }

  getAllSpectacleDispenseForms() {
    this.spectacleDispensingFormsItem = this.afs
      .collection("spectacleDispensingForms", (ref) => {
        return ref;
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as SpectacleForm;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.spectacleDispensingFormsItem;
  }

  getAllReferralSlips() {
    this.referralSlipsItem = this.afs
      .collection("referralSlips", (ref) => {
        return ref;
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as ReferralSlip;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.referralSlipsItem;
  }

  getAllUsers() {
    this.usersItem = this.afs
      .collection("users", (ref) => ref.orderBy("email", "desc"))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.usersItem;
  }

  getHigherFacilities1() {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("phc", "==", "true").orderBy("email", "desc");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.usersItem;
  }


  getPhcType(district) {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("phc", "==", "true").where("type", "==", "PHC").where("district", "==", district).orderBy("email", "desc");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.usersItem;
  }

  getUhcType(district) {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("phc", "==", "true").where("type", "==", "UPHC/UHWC").where("district", "==", district).orderBy("email", "desc");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.usersItem;
  }

  getChcType(district) {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("phc", "==", "true").where("type", "==", "CHC/CH/SDH").where("district", "==", district).orderBy("email", "desc");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
    return this.usersItem;
  }



  getDhType(district) {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("phc", "==", "true").where("type", "==", "DH").where("district", "==", district).orderBy("email", "desc");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          }); 
        })
      );
    return this.usersItem;
  }
  getNgoType(district) {
    this.usersItem = this.afs
      .collection("users", (ref) => {
        return ref.where("phc", "==", "true").where("type", "==", "NGO").where("district", "==", district).orderBy("email", "desc");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          }); 
        })
      );
    return this.usersItem;
  }
  


  addAssessment(data, id) {
    this.afs.firestore
      .collection("assessmentForms")
      .doc(id)
      .get()
      .then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          const alert = await this.alertCtrl.create({
            header: "Error",
            message: "Report already submitted for the selected month!",
            buttons: ["Dismiss"],
          });
          await alert.present();
        } else {
          this.assessmentFormsCollection.doc(id).set(data);
        }
      });
  }

  addSpec(data, id) {
    this.assessmentFormsCollection.doc(`${id}`).update(data);
  }

  addSpectacle(data, id) {
    this.afs.firestore
      .collection("spectacleDispensingForms")
      .doc(id)
      .get()
      .then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          const alert = await this.alertCtrl.create({
            header: "Error",
            message: "Report already submitted for the selected month!",
            buttons: ["Dismiss"],
          });
          await alert.present();
        } else {
          this.spectacleDispensingFormsCollection.doc(id).set(data);
        }
      });
  }
  
  increaseCounter(data) {
      this.counterCollection.doc('counter').update(data);
  }

  updateUID(id, uid) {
    this.assessmentFormsCollection.doc(id).update(uid);
  }

  getNinId() {

  }

  addReferral(data, id) {
    this.afs.firestore
      .collection("referralSlips")
      .doc(id)
      .get()
      .then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          const alert = await this.alertCtrl.create({
            header: "Error",
            message: "Report already submitted for the selected month!",
            buttons: ["Dismiss"],
          });
          await alert.present();
        } else {
          this.referralSlipsCollection.doc(id).set(data);
        }
      });
  }

  updateFacility(key, data) {
    this.usersCollection.doc(`${key}`).update(data);
  }

  updateAssessmentFromFollowUp(key, data) {
    this.assessmentFormsCollection.doc(`${key}`).update(data);
  }

  deleteAssessment(id) {
    this.assessmentFormsCollection.doc(`${id}`).delete();
  }
}
