// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCUFI9XgLg2sHBf4iBcHR2mjnt2Q77IoIw",
    authDomain: "eye-care-6241f.firebaseapp.com",
    projectId: "eye-care-6241f",
    storageBucket: "eye-care-6241f.appspot.com",
    messagingSenderId: "240131508489",
    appId: "1:240131508489:web:74db6fb880b5f0bc012bd8",
    measurementId: "G-K33FP2BZ9X",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
