import { Injectable, NgZone, OnDestroy } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { User } from "../model/aio.model";
import { AlertController, LoadingController } from "@ionic/angular";

import { Subscription } from "rxjs";
import { AioService } from "./aio.service";
import { switchMap, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService implements OnDestroy {
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;

  myUser: any;

  data: any;

  currentUser: User[] = [];

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = "";
  userData: any;
  ngZone: NgZone;
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private aioServ: AioService
  ) {
    this.sub2 = this.afAuth.authState.subscribe((user) => {

      if (user) {
        this.userData = user;
        sessionStorage.setItem("user", JSON.stringify(this.userData));
        this.isAuthenticated.next(true);
        JSON.parse(sessionStorage.getItem("user")!);
      } else {
        sessionStorage.setItem("user", "null");
        this.isAuthenticated.next(false);
        JSON.parse(sessionStorage.getItem("user")!);
      }
    });
  }

  ngOnDestroy(): void {
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
    this.sub3.unsubscribe();
    this.myUser = "";
    this.data = "";
  }

  signInAdmin(email, password) {
    this.loadingCtrl
      .create({
        message: "Please wait...",
      })
      .then(async (loadingEl) => {
        await loadingEl.present();
        return this.afAuth
          .signInWithEmailAndPassword(email, password)
          .then((result) => {
            this.SetUserData(result.user as User);
            this.sub1 = this.afAuth.authState.subscribe(async (user) => {
              if (user) {
                this.router.navigate(["admin"]);
                await loadingEl.dismiss();
                return this.isAuthenticated.next(true);
              }
            });
          })
          .catch(async (error) => {
            const alert = await this.alertCtrl.create({
              header: "Connot Sign In",
              message: "Wrong username or password! Contact administrator.",
              buttons: ["Dismiss"],
            });
            await alert.present();
          });
      });
  }

  signInSuper(email, password) {
    this.loadingCtrl
      .create({
        message: "Please wait...",
      })
      .then(async (loadingEl) => {
        await loadingEl.present();
        return this.afAuth
          .signInWithEmailAndPassword(email, password)
          .then((result) => {
            this.SetUserData(result.user as User);
            this.afAuth.authState.subscribe(async (user) => {
              if (user) {
                this.router.navigate(["super"]);
                await loadingEl.dismiss();
                return this.isAuthenticated.next(true);
              }
            });
          })
          .catch(async (error) => {
            const alert = await this.alertCtrl.create({
              header: "Connot Sign In",
              message:
                "Wrong username or password! Please Contact administrator.",
              buttons: ["Dismiss"],
            });
            await alert.present();
          });
      });
  }

  changeRoute(myUser: { phc: any }, loadingEl: HTMLIonLoadingElement) {
    loadingEl.dismiss();
    this.data = myUser;
    if (this.data[0].email.includes("shc") || this.data[0].email.includes("hwc")) {
      window.location.reload();
      loadingEl.dismiss();
      this.router.navigate(["tabs/home"]);
      return true;
    } else if (this.data[0].email.includes("phc") || this.data[0].email.includes("chc") || this.data[0].email.includes("ch")) {
      window.location.reload();
      loadingEl.dismiss();
      this.router.navigate(["tabs/tab2"]);
      return true;
    } else {
      return false;
    }
  }

  signInFacility(email, password) {
    this.loadingCtrl
      .create({
        message: "Please wait...",
      })
      .then(async (loadingEl) => {
        return this.afAuth
          .signInWithEmailAndPassword(email, password)
          .then(async (result) => {
            await this.SetUserData(result.user as User);

            this.sub3 = this.afAuth.authState
              .pipe(switchMap((user) => this.aioServ.getUser(user.email)))
              .subscribe(async (x) => {
                this.myUser = x;
                if (this.myUser[0].email.includes('super')) {
                  this.router.navigateByUrl("super");
                } else {
                  this.changeRoute(this.myUser, loadingEl);
                }
              });
            loadingEl.dismiss();
          });
      })
      .catch(async (error) => {
        const alert = await this.alertCtrl.create({
          header: "Connot Sign In",
          message: "Wrong username or password! Contact administrator.",
        });
        await alert.present();
        // await loadingEl.dismiss();
      });
  }

  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.currentUser = [];
    return this.afAuth.signOut().then(() => {
      sessionStorage.removeItem("user");
    });
  }

  // logout() {
  //   this.isAuthenticated.next(false);
  //   this.currentUser = [];
  //   this.afAuth.signOut();
  //   sessionStorage.removeItem("user");
  // }

  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem("user") as string);
    return user !== null ? true : false;
  }
}
